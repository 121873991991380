<template>
  <div>
    <v-data-table
      v-model="selectedMembers"
      :headers="computedHeaders"
      :items="members"
      :loading="loading"
      :loading-text="$t('loading')"
      :footer-props="footerProps"
      :show-expand="showExpand"
      :single-expand="true"
      :show-select="!managed"
      :search="userSearch"
      :custom-filter="search"
      :hide-default-header="members.length < 1"
      :items-per-page="50"
    >
      <template v-if="members.length > 0" #top>
        <v-expand-transition>
          <v-toolbar v-show="selectedMembers.length < 1" flat>
            <v-toolbar-title>{{ $t('members') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items class="d-flex align-center">
              <v-text-field v-model="userSearch" :label="$t('search')" append-icon="fa-search" />
            </v-toolbar-items>
          </v-toolbar>
        </v-expand-transition>
        <v-expand-transition>
          <v-toolbar v-show="selectedMembers.length > 0" flat dark color="blueDark">
            <v-toolbar-title>{{ $t('editRows') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn text @click="cancelBatchEdit">{{ $t('cancel') }}</v-btn>
              <v-btn text @click="promptRemoveSelectedMembers"
                ><v-icon left>fa-trash</v-icon>{{ $t('remove') }}</v-btn
              >
            </v-toolbar-items>
          </v-toolbar>
        </v-expand-transition>
      </template>

      <template #[`header.data-table-select`]="{ props, on }">
        <v-btn v-if="props.value" :key="'members' + 'deselect'" icon @click="on.input(false)"
          ><v-icon>fa-check-square</v-icon></v-btn
        >
        <v-btn v-else :key="'members' + 'select'" icon @click="on.input(true)"
          ><v-icon>far fa-square</v-icon></v-btn
        >
      </template>

      <template #[`item.data-table-select`]="{ item, select, isSelected }">
        <v-btn v-if="isSelected" :key="item.generatedId + 'deselect'" icon @click="select(false)"
          ><v-icon>fa-check-square</v-icon></v-btn
        >
        <v-btn v-else :key="item.generatedId + 'select'" icon @click="select(true)"
          ><v-icon>far fa-square</v-icon></v-btn
        >
      </template>

      <template #[`item.profilePhoto`]="{ item }">
        <profile-photo
          :url="item.profilePhoto"
          :user-name="item.name"
          :class="{ deleted: item.isSoftDeleted }"
        />
      </template>

      <template #[`item.name`]="{ item }">
        <span :class="{ deleted: item.isSoftDeleted }"> {{ item.name }} </span>
      </template>

      <template #[`item.email`]="{ item }">
        <span :class="{ deleted: item.isSoftDeleted }"> {{ item.email }} </span>
      </template>

      <template #[`item.mobile`]="{ item }">
        <span :class="{ deleted: item.isSoftDeleted }"> {{ item.mobile }} </span>
      </template>

      <template #[`item.actions`]="{ item }">
        <template v-if="showActionText">
          <v-btn
            :disabled="selectedMembers.length > 0 || managed"
            @click="promptRemoveMember(item)"
          >
            <v-icon left>fa-trash</v-icon>
            {{ $t('remove') }}
          </v-btn>
        </template>

        <template v-else>
          <v-btn
            :disabled="selectedMembers.length > 0 || managed"
            icon
            @click="promptRemoveMember(item)"
          >
            <v-icon class="pa-1">fa-trash</v-icon>
          </v-btn>
        </template>
      </template>

      <template #[`item.data-table-expand`]="data">
        <v-btn :key="data.item.id + data.isExpanded" icon @click="data.expand(!data.isExpanded)">
          <v-icon v-if="data.isExpanded">fa-angle-up</v-icon>
          <v-icon v-else>fa-angle-down</v-icon>
        </v-btn>
      </template>

      <template v-if="showExpand" #expanded-item="{ item, headers }">
        <td :colspan="headers.length">
          <v-container class="pa-0">
            <v-row align="center">
              <v-col class="text-center" :class="{ deleted: item.isSoftDeleted }">
                <label class="font-weight-bold">{{ $t('email') }}:</label>
                {{ item.email }}
              </v-col>

              <v-col class="text-center" :class="{ deleted: item.isSoftDeleted }">
                <label class="font-weight-bold">{{ $t('phone') }}:</label>
                {{ item.mobile }}
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>

      <template #no-data>
        <empty-state
          :title="$t('noMembers')"
          :message="$t('noMembersMsg')"
          icon-src="@/assets/images/empty-states/team-members.svg"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ProfilePhoto from '@/components/ProfilePhoto';
import confirmDialog from '@/components/dialog/confirmDialog';
import errorMessage from '@/util/errorMessage';
import api from '@/util/api';
import EmptyState from '@/components/EmptyState';

export default {
  name: 'TeamMembers',

  components: {
    ProfilePhoto,
    EmptyState,
  },

  props: {
    teamId: {
      type: Number,
      required: true,
    },

    members: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      required: false,
    },

    managed: {
      type: Boolean,
    },
  },

  data: (self) => ({
    selectedMembers: [],
    userSearch: '',

    headers: [
      {
        value: 'profilePhoto',
        text: self.$t('photo'),
      },
      {
        value: 'name',
        sortable: true,
      },
      {
        value: 'email',
        hide: true,
        sortable: true,
      },
      {
        value: 'mobile',
        text: self.$t('phone'),
        hide: true,
        sortable: true,
      },
      {
        value: 'actions',
        align: 'end',
        text: self.$t('actions'),
      },
    ].map((header) => ({
      ...header,
      text: header.text || self.$t(header.value),
      sortable: header.sortable === true ? true : false,
    })),

    footerProps: {
      itemsPerPageOptions: [10, 50, 100],
    },
  }),

  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) =>
          !h.hide || !this.$vuetify.breakpoint.mdAndDown || this.$vuetify.breakpoint.width < 600,
      );
    },

    showExpand() {
      return this.$vuetify.breakpoint.mdAndDown && this.$vuetify.breakpoint.width >= 600;
    },

    showActionText() {
      return this.$vuetify.breakpoint.width < 600;
    },
  },

  methods: {
    async promptRemoveMember(member) {
      const confirmed = await confirmDialog(
        this.$t('removeMember'),
        this.$t('removeMemberFromTeamMsg', null, {
          userEmail: member.email,
        }),
        this.$t('cancel'),
        this.$t('remove'),
      ).open();

      if (confirmed) {
        try {
          await api.removeTeamMembers({
            teamId: this.teamId,
            users: [member.userId],
          });

          this.$emit('memberRemoved');
        } catch (error) {
          errorMessage(this.$t('removeMemberError'), this.$t('removeMemberErrorMsg'), error);
        }
      }
    },

    search: (value, search, item) => {
      const name = item.name.toLowerCase();
      const email = item.email.toLowerCase();
      const mobile = item.mobile;

      search = search.toLowerCase();

      return name.indexOf(search) >= 0 || email.indexOf(search) >= 0 || mobile.indexOf(search) >= 0;
    },

    async promptRemoveSelectedMembers() {
      const confirmed = await confirmDialog(
        this.$t('removeMembers'),
        this.$t('removeMembersFromTeamMsg', null, {
          count: this.selectedMembers.length,
        }),
        this.$t('cancel'),
        this.$t('remove'),
      ).open();

      if (confirmed) {
        try {
          await api.removeTeamMembers({
            teamId: this.teamId,
            users: this.selectedMembers.map((member) => member.id),
          });

          this.selectedMembers = [];
          this.$emit('memberRemoved');
        } catch (error) {
          errorMessage(this.$t('memberRemovedError'), this.$t('memberRemovedErrorMsg'), error);
        }
      }
    },

    cancelBatchEdit() {
      this.selectedMembers = [];
    },
  },
};
</script>
