<template>
  <div class="d-flex justify-center">
    <div>
      <div class="d-flex justify-center pa-12">
        <v-img :src="iconSrc" max-width="100px" contain />
      </div>
      <div class="text-h6 font-weight-bold text-center">{{ title }}</div>
      <div class="text-center">{{ message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',

  props: {
    title: {
      type: String,
      required: true,
    },

    message: {
      type: String,
      required: true,
    },

    iconSrc: {
      type: String,
      required: true,
    },
  },
};
</script>
