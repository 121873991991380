<template>
  <v-autocomplete
    v-model="selected"
    :label="label"
    :items="filteredUsers"
    item-text="name"
    item-value="id"
    :return-object="returnObject"
    :rules="rules"
    :multiple="multiple"
    chips
    :search-input.sync="userSearch"
    :prepend-icon="prependIcon"
    :messages="messages"
    :hint="hint"
    :persistent-hint="persistentHint"
    :error-messages="errorMessages"
    :disabled="disabled"
    @change="onChange"
  >
    <template #selection="{ item }">
      <v-list-item-avatar size="50px" :class="{ deleted: item.isSoftDeleted }">
        <profile-photo :user-name="item.name" :url="item.profilePhoto" :clickable="false" />
      </v-list-item-avatar>
      <v-list-item-content :class="{ deleted: item.isSoftDeleted }">
        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item-content>
    </template>

    <template #item="{ item, attrs }">
      <v-list-item-avatar size="50px" :class="{ deleted: item.isSoftDeleted }">
        <profile-photo :user-name="item.name" :url="item.profilePhoto" :clickable="false" />
      </v-list-item-avatar>
      <v-list-item-content :class="{ deleted: item.isSoftDeleted }">
        <v-list-item-title>{{ item.name }}</v-list-item-title>
        <v-list-item-subtitle v-if="showIds">ID: {{ item.id }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon v-if="multiple" :key="item.id + attrs.inputValue">
        <v-icon v-if="attrs.inputValue" color="primary">fa-check-square</v-icon>
        <v-icon v-else>far fa-square</v-icon>
      </v-list-item-icon>
    </template>

    <template v-if="clearable && selected.length > 0" #append>
      <v-btn
        icon
        @click.stop="
          selected = [];
          onChange();
        "
        ><v-icon class="pa-1">fa-times</v-icon></v-btn
      >
    </template>
  </v-autocomplete>
</template>

<script>
import ProfilePhoto from '@/components/ProfilePhoto';
import api from '@/util/api';

export default {
  name: 'UserSelector',

  components: {
    ProfilePhoto,
  },

  props: {
    value: {
      type: [Array, Number, Object],
      required: false,
      default: null,
    },

    prependIcon: {
      type: String,
      required: false,
      default: null,
    },

    showIds: {
      type: Boolean,
      required: false,
      default: false,
    },

    returnObject: {
      type: Boolean,
      required: false,
      default: false,
    },

    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },

    rules: {
      type: Array,
      required: false,
      default: () => [],
    },

    label: {
      type: String,
      required: false,
      default: null,
    },

    messages: {
      type: [String, Array],
      required: false,
      default: () => [],
    },

    hint: {
      type: String,
      required: false,
      default: undefined,
    },

    persistentHint: {
      type: Boolean,
      required: false,
      default: false,
    },

    errorMessages: {
      type: [String, Array],
      required: false,
      default: () => [],
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    filter: {
      type: Function,
      required: false,
      default: () => true,
    },

    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    users: [],
    userSearch: '',
    selected: null,
  }),

  computed: {
    filteredUsers() {
      return this.users.filter(this.filter);
    },
  },

  watch: {
    value() {
      this.selected = this.value;
    },
  },

  created() {
    this.selected = this.value;
  },

  mounted() {
    this.loadUsers();
  },

  methods: {
    async loadUsers() {
      const res = await api.getUsers();
      this.users = res.users;
    },

    onChange() {
      this.teamSearch = '';
      this.$emit('input', this.selected);
    },
  },
};
</script>

<style></style>
