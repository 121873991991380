import { translate as t } from '@/util/i18n';

const isRequired = (name, value) =>
  !!value ||
  t('isRequired', null, {
    value: t(name),
  });

const minimum = (name, value, characters) =>
  (!!value && value.length >= characters) ||
  t('mustBeMinimum', null, {
    value: t(name),
    characters,
  });

const maximum = (name, value, characters) =>
  !value ||
  value.length <= characters ||
  t('mustBeMaximum', null, {
    value: t(name),
    characters,
  });

export const nameRules = () => [
  (name) => isRequired('name', name),
  (name) => minimum('name', name, 3),
  (name) => maximum('name', name, 100),
];

export const descriptionRules = () => [(name) => maximum('name', name, 300)];
