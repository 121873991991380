<template>
  <div>
    <v-container>
      <div class="d-flex align-start my-12">
        <div>
          <div class="text-subtitle-1 text-uppercase">{{ $t('team') }}</div>
          <div class="text-h4 mb-3">{{ name }}</div>
          <div class="mb-3">{{ description }}</div>
          <div>
            <v-icon color="blueDark" class="pa-1">fa-fw fa-users</v-icon>
            {{ membersCount }} {{ $t('members') }}
          </div>
        </div>
        <div class="ml-12">
          <v-btn color="primary" fab small :disabled="managed" @click="edit">
            <v-icon class="pa-1">fa-pen</v-icon>
          </v-btn>

          <v-dialog v-model="editTeamDialog" max-width="500px" eager>
            <v-card>
              <v-card-title>{{ $t('edit') }} {{ name }}</v-card-title>
              <v-card-text>
                <v-text-field
                  v-model="editForm.name"
                  :counter="100"
                  :rules="nameRules"
                  :label="$t('teamName')"
                  prepend-icon="fa-tag"
                />

                <v-textarea
                  v-model="editForm.description"
                  :counter="300"
                  :rules="descriptionRules"
                  :label="$t('description')"
                  prepend-icon="fa-info-circle"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text @click="cancelEdit">{{ $t('cancel') }}</v-btn>
                <v-btn color="primary" @click="saveTeam">{{ $t('save') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>

      <v-row v-if="!managed" class="align-center">
        <v-col cols="8">
          <user-selector
            v-model="usersToAdd"
            multiple
            clearable
            :filter="filterOutMembersAlreadyInTeam"
            :label="$t('addTeamMembers')"
          />
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" :disabled="usersToAdd.length < 1" @click="addTeamMembers">{{
            $t('add')
          }}</v-btn>
        </v-col>
      </v-row>

      <div v-else>
        <v-alert type="info" color="gray600">{{ $t('managedTeamInfo') }}</v-alert>
      </div>

      <div>
        <team-members
          :team-id="teamId"
          :loading="loading"
          :members="members"
          :managed="managed"
          @memberRemoved="loadTeam"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import auth from '@/util/auth';
import is from '@/lib/util/is';
import UserSelector from '@/components/UserSelector';
import confirmDialog from '@/components/dialog/confirmDialog';
import api from '@/util/api';
import TeamMembers from '@/views/company/teams/TeamMembers';
import errorMessage from '@/util/errorMessage';
import { nameRules, descriptionRules } from '@/consts/teamRules';

export default {
  name: 'Team',

  components: {
    UserSelector,
    TeamMembers,
  },

  data: () => ({
    loading: null,
    editTeamDialog: null,
    teamId: -1,
    name: '',
    description: '',
    members: [],
    managed: false,
    usersToAdd: [],
    editForm: {
      name: '',
      description: '',
    },

    nameRules: nameRules(),
    descriptionRules: descriptionRules(),
    events: [],
  }),

  computed: {
    membersCount() {
      return this.members.length;
    },
  },

  mounted() {
    if (is.number(this.$route.params.id)) {
      this.teamId = parseInt(this.$route.params.id, 10);
    }

    this.loadTeam();
    this.subscribeToSignalREvents(auth.getCustomerId());
  },
  beforeDestroy() {
    this.unregisterSignalR();
  },

  methods: {
    async loadTeam() {
      this.loading = true;
      const res = await api.getTeam(this.teamId);
      const team = res.team;
      this.name = team.name;
      this.description = team.description || this.$t('noDescription');
      this.members = team.members.map((member) => ({
        ...member,
        id: member.userId,
      }));
      this.managed = team.managed;
      this.loading = false;
    },

    filterOutMembersAlreadyInTeam(user) {
      return !this.members.find((member) => member.userId === user.id);
    },

    edit() {
      this.editForm.name = this.name;
      this.editForm.description = this.description;
      this.editTeamDialog = true;
    },

    cancelEdit() {
      this.editTeamDialog = false;
    },

    async saveTeam() {
      try {
        await api.updateTeam({
          id: this.teamId,
          ...this.editForm,
        });

        this.cancelEdit();
        this.loadTeam();
      } catch (error) {
        errorMessage(this.$t('updateTeamError'), this.$t('updateTeamErrorMsg'), error);
      }
    },

    async addTeamMembers() {
      const confirmed = await confirmDialog(
        this.$t('addTeamMembers'),
        this.$t('addTeamMembersMsg'),
        this.$t('cancel'),
        this.$t('add'),
      ).open();

      if (confirmed) {
        await api.addTeamMembers({
          teamId: this.teamId,
          users: this.usersToAdd,
        });

        this.loadTeam();
        this.usersToAdd = [];
      }
    },
    subscribeToSignalREvents(customerId) {
      this.events.forEach((event) => this.$signalR.off(event));
      const event = `teamsUpdated:${customerId}`;
      this.events.push(event);
      this.$signalR.on(event, (data) => {
        if (customerId == event.split(':')[1]) {
          var teamId = data.teamId;
          if (this.teamId == teamId) {
            this.loadTeam();
          }
        }
      });
    },
    unregisterSignalR() {
      this.events.forEach((event) => this.$signalR.off(event));
    },
  },
};
</script>
